import React from 'react';

import CircuitInfo from '../../CircuitInfo/CircuitInfo';
import Layout from '../../../furniture/Layout/Layout';
import SectionTitle from '../../../furniture/SectionTitle/SectionTitle';
import StandingsStats from '../../StandingsStats/StandingsStats';

const PreRace = ({
  driverStandings,
  fastestLap,
  numberOfLaps,
  previousWinners,
  race,
  teamStandings,
}) => {
  return (
    <Layout>
      <CircuitInfo
        fastest={fastestLap}
        numberOfLaps={numberOfLaps}
        race={race}
        winners={previousWinners}
      />

      {driverStandings.length && teamStandings.length ? (
        <>
          <SectionTitle title={'Previously'} />
          <StandingsStats
            driverStandings={driverStandings}
            preRace={true}
            round={race.round}
            teamStandings={teamStandings}
          />
        </>
      ) : null}
    </Layout>
  );
};

export default PreRace;
