import React from 'react';
import classNames from 'classnames';

import styles from './Stat.module.scss';

const Stat = ({ icon, stat, statSuffix, text, winner }) => {
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.icon, styles[icon])} />
      <h1 className={styles.stat}>
        <span className={styles.statText}>{stat}</span>
        <span className={styles.statSuffix}>{statSuffix}</span>
      </h1>
      <h2 className={styles.text}>{text}</h2>
      <h2 className={styles.winner}>{winner}</h2>
    </div>
  );
};

export default Stat;
