import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Layout from '../../furniture/Layout/Layout';

import styles from './RaceSelect.module.scss';
import getTeamNameClassName from '../../../utils/getTeamNameClassName';
import SectionTitle from "../../furniture/SectionTitle/SectionTitle"

const RaceSelect = ({ races, selected }) => {
  return (
    <Layout>
      <SectionTitle title={`Round ${selected} of ${races.length}`} />
      <ol className={styles.list}>
        {races.map((race) => {
          return (
            <li
              className={styles.race}
              key={race.round}
            >
              <Link
                className={classNames(
                  styles.roundLink,
                  selected === race.round ? styles.selected : null,
                  getTeamNameClassName(race.winner?.team || 'unknown')
                )}
                to={`/season/${race.season}/${race.round}`}
              >
                {race.round}
              </Link>
            </li>
          );
        })}
      </ol>
    </Layout>
  );
};

export default RaceSelect;
