import React, { useState } from 'react';
import classNames from 'classnames';

import Layout from '../../furniture/Layout/Layout';
import SectionTitle from '../../furniture/SectionTitle/SectionTitle';

import styles from './Qualifying.module.scss';

const sortByQualifyingPosition = (a, b) => {
  if (a.qualifying && a.qualifying.position === 0) return 1;
  if (b.qualifying && b.qualifying.position === 0) return -1;

  return a.qualifying && b.qualifying
    ? a.qualifying.position - b.qualifying.position
    : 0;
};

const Qualifying = ({ results }) => {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <SectionTitle title="Qualifying" />
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={classNames(styles.header, styles.position)}>#</th>
              <th className={classNames(styles.header, styles.driver)}>
                Driver
              </th>
              <th className={classNames(styles.header, styles.team)}>Team</th>
              <th className={classNames(styles.header, styles.time)}>Q1</th>
              <th className={classNames(styles.header, styles.time)}>Q2</th>
              <th className={classNames(styles.header, styles.time)}>Q3</th>
            </tr>
          </thead>
          <tbody>
            {results.sort(sortByQualifyingPosition).map((result) => {
              return (
                <tr key={result.driver.slug}>
                  <td className={styles.position}>
                    {result.qualifying?.position}
                  </td>
                  <td className={styles.driver}>{result.driver.name}</td>
                  <td className={styles.team}>{result.team.name}</td>
                  <td className={styles.time}>{result.qualifying?.q1}</td>
                  <td className={styles.time}>{result.qualifying?.q2}</td>
                  <td className={styles.time}>{result.qualifying?.q3}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default Qualifying;
