import React from 'react';

import GridPosition from './GridPosition/GridPosition';
import SectionTitle from '../../furniture/SectionTitle/SectionTitle';

import styles from './StartingGrid.module.scss';

const sortByGridPosition = (a,b) => {
  if (a.grid === 0) return 1;
  if (b.grid === 0) return -1;

  return a.grid - b.grid;
}
const getQualifyingTime = ((qualifying, season) => {
  if (qualifying && qualifying.q3) {
    return {
      position: qualifying.position,
      round: 'Q3',
      time: qualifying.q3,
    };
  } else if (qualifying && qualifying.q2) {
    return {
      position: qualifying.position,
      round: 'Q2',
      time: qualifying.q2
    }
  } else if (qualifying && qualifying.q1) {
    return  {
      position: qualifying.position,
      round: season >= 2005 ? 'Q1' : '',
      time: qualifying.q1
    }
  } else {
    return {
      position: qualifying && qualifying.position || undefined,
      time: 'No time set'
    }
  }

})

const StartingGrid = ({ results, season }) => {
  return (
    <div className={styles.wrapper}>
      <SectionTitle title="Starting Grid" />
      <ol className={styles.list}>
        {results.sort(sortByGridPosition).map(result => <li className={styles.listItem} key={result.grid}>
          <GridPosition driverName={result.driver.name} driverSlug={result.driver.slug} position={result.grid === 0 ? 'PIT' : result.grid} teamName={result.team.name} teamSlug={result.team.slug} time={getQualifyingTime(result.qualifying, season)} />
        </li>)}
      </ol>
    </div>
  );
};

export default StartingGrid;
