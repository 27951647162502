import React from 'react';
import { graphql } from 'gatsby';

import Header from '../components/race/Header/Header';
import PageLayout from '../components/PageLayout/PageLayout';
import RaceSelect from '../components/race/RaceSelect/RaceSelect';
import SEO from '../components/seo';
import Section from '../components/furniture/Section/Section';
import getDate from '../utils/getDate';
import getMonth from '../utils/getMonth';
import getYear from '../utils/getYear';
import mergeDataSources from '../utils/mergeDataSources';

import PreRace from '../components/race/sections/PreRace/PreRace';
import PostRace from '../components/race/sections/PostRace/PostRace';
import Qualifying from '../components/race/sections/Qualifying/Qualifying';
import Race from '../components/race/sections/Race/Race';

const RacePage = ({ data }) => {
  const {
    fastestLap,
    previousWinners: { nodes: previousWinners },
    allDrivers: { nodes: drivers },
    allDriversPastSeason: { nodes: pastDrivers },
    allTeamsPastSeason: { nodes: pastTeams },
    allRaces: { nodes: races },
    previousDriverStandings: { nodes: previousDriverStandingsData },
    driverStandings: { nodes: driverStandingsData },
    allLapsLead: { nodes: allLapsLead },
    allQualifying: { nodes: qualifying },
    allRaceResults: { nodes: allRaceResults },
    allSeasonRaces: { nodes: allSeasonRaces },
    allRaceStats: { nodes: allRaceStats },
    allTeams: { nodes: teams },
    previousTeamStandings: { nodes: previousTeamStandingsData },
    teamStandings: { nodes: teamStandingsData },
    videos: { nodes: videos },
  } = data;

  const race = races[0];

  const results = mergeDataSources(
    allRaceResults.map((result) => ({ ...result, qualifying: result.driver })),
    ['driver', 'team', 'qualifying'],
    [drivers, teams, qualifying]
  );

  const previousDriverStandings = mergeDataSources(
    previousDriverStandingsData,
    ['driver', 'team'],
    [pastDrivers, pastTeams]
  );

  const previousTeamStandings = mergeDataSources(
    previousTeamStandingsData,
    ['team'],
    [pastTeams]
  );

  const driverStandings = mergeDataSources(
    driverStandingsData,
    ['driver', 'team'],
    [drivers, teams]
  );

  const teamStandings = mergeDataSources(
    teamStandingsData,
    ['team'],
    [teams]
  );

  const stats = {};

  if (allRaceStats[0]) {
    stats.drivers = mergeDataSources(
      allRaceStats[0].drivers,
      ['driver'],
      [drivers]
    );

    stats.teams = mergeDataSources(allRaceStats[0].teams, ['team'], [teams]);
  }

  return (
    <PageLayout>
      <SEO title={`Season`} />

      <Header
        country={race.circuit.location.country}
        description={`${getDate(race.date)} ${getMonth(
          race.date,
          'long'
        )} ${getYear(race.date)}`}
        shortTitle={race.circuit.location.country}
        title={`${race.name} ${getYear(race.date)}`}
        winningTeam={
          results.find((result) => result.position === '1')?.team.slug
        }
      />

      <RaceSelect races={allSeasonRaces} selected={race.round} />

      <Section theme={'light'}>
        <PreRace
          driverStandings={previousDriverStandings}
          fastestLap={fastestLap}
          numberOfLaps={
            (results || []).find((result) => result.position === '1')?.laps
          }
          previousWinners={previousWinners}
          race={race}
          teamStandings={previousTeamStandings}
        />
      </Section>

      {race.hasResults ? (
        <>
          <Section theme={'light'}>
            <Qualifying
              results={results}
              stats={stats}
              video={(videos || []).find(
                (video) => video.type === 'qualifying'
              )}
            />
          </Section>

          <Section>
            <Race
              allLapsLead={allLapsLead}
              drivers={drivers}
              race={race}
              results={results}
              video={(videos || []).find((video) => video.type === 'race')}
            />
          </Section>

          <PostRace
            driverStandings={driverStandings}
            race={race}
            teamStandings={teamStandings}/>
        </>
      ) : null}
    </PageLayout>
  );
};

export const query = graphql`
  query(
    $season: Int
    $round: Int
    $slug: String
    $historicalSeason: Int
    $previousSeason: Int
    $previousRound: Int
  ) {
    allDrivers(filter: { season: { eq: $season } }) {
      nodes {
        code
        name
        nationality
        number
        slug
      }
    }
    allDriversPastSeason: allDrivers(
      filter: { season: { gte: $historicalSeason } }
    ) {
      nodes {
        name
        slug
      }
    }
    allTeamsPastSeason: allTeams(
      filter: { season: { gte: $historicalSeason } }
    ) {
      nodes {
        name
        slug
      }
    }
    allLapsLead(filter: { season: { eq: $season }, round: { eq: $round } }) {
      nodes {
        season
        round
        driver
        lapsLead
      }
    }
    previousWinners: allCircuitWinners(filter: { circuit: { eq: $slug } }) {
      nodes {
        driver {
          name
          slug
        }
        team {
          name
          slug
        }
        season
        round
      }
    }
    fastestLap: circuitFastest(circuit: { eq: $slug }) {
      driver {
        name
        slug
      }
      season
      team {
        name
        slug
      }
      time
    }
    allTeams(filter: { season: { eq: $season } }) {
      nodes {
        name
        nationality
        slug
      }
    }
    allQualifying(filter: { season: { eq: $season }, round: { eq: $round } }) {
      nodes {
        slug
        q1
        q2
        q3
        position
      }
    }
    allRaceResults(filter: { season: { eq: $season }, round: { eq: $round } }) {
      nodes {
        season
        round
        driver
        team
        grid
        laps
        points
        position
        time
      }
    }
    allSeasonRaces: allRaces(filter: { season: { eq: $season } }) {
      nodes {
        name
        round
        season
        hasResults
        circuit {
          location {
            country
          }
        }
        winner {
          driver
          team
        }
      }
    }
    allRaces(filter: { season: { eq: $season }, round: { eq: $round } }) {
      nodes {
        name
        round
        season
        hasResults
        circuit {
          name
          location {
            city
            country
            lat
            lng
          }
        }
        winner {
          driver
          team
          time
        }
        fastestQualifying {
          times {
            q1
            q2
            q3
          }
          team
          driver
        }
        fastestLap {
          driver
          team
          time
        }
        date
        time
        pole {
          driver
          team
        }
      }
    }
    allRaceStats(filter: { season: { eq: $season }, round: { eq: $round } }) {
      nodes {
        season
        round
        drivers {
          driver
          poles
          consecutivePoles
          wins
          consecutiveWins
          podiums
          consecutivePodiums
          fastest
          consecutiveFastest
          races
          lastRace
        }
        teams {
          team
          poles
          consecutivePoles
          wins
          consecutiveWins
          podiums
          consecutivePodiums
          fastest
          consecutiveFastest
          races
          lastRace
        }
      }
    }
    previousDriverStandings: allDriverStandings(
      filter: { season: { eq: $previousSeason }, round: { eq: $previousRound } }
    ) {
      nodes {
        season
        round
        driver
        team
        points
        position
        previous
        wins
        gainedPoints
        fastestLap
      }
    }
    previousTeamStandings: allTeamStandings(
      filter: { season: { eq: $previousSeason }, round: { eq: $previousRound } }
    ) {
      nodes {
        season
        round
        team
        points
        position
        previous  
        wins
        gainedPoints
        fastestLap
      }
    }
    driverStandings: allDriverStandings(
      filter: { season: { eq: $season }, round: { eq: $round } }
    ) {
      nodes {
        season
        round
        driver
        team
        points
        position
        previous  
        wins
        gainedPoints
        fastestLap
      }
    }
    teamStandings: allTeamStandings(
      filter: { season: { eq: $season }, round: { eq: $round } }
    ) {
      nodes {
        season
        round
        team
        points
        position
        previous  
        wins
        gainedPoints
        fastestLap
      }
    }
    articles: allRaceArticles(
      filter: { season: { eq: $season }, round: { eq: $round } }
    ) {
      nodes {
        source
        title
        url
      }
    }
    videos: allRaceVideos(
      filter: { season: { eq: $season }, round: { eq: $round } }
    ) {
      nodes {
        type
        url
      }
    }
  }
`;

export default RacePage;
