import React from 'react';

import Layout from '../../../furniture/Layout/Layout';
import SeasonLeaderBoard from '../../../SeasonLeaderBoard/SeasonLeaderBoard';
import SectionTitle from '../../../furniture/SectionTitle/SectionTitle';
import StandingsStats from '../../StandingsStats/StandingsStats';

const PostRace = ({ driverStandings, race, teamStandings }) => {
  return (
    <Layout>
      <SectionTitle title={'Post Race'} />

      <StandingsStats
        driverStandings={driverStandings}
        preRace={false}
        round={race.round}
        teamStandings={teamStandings}
      />

      <SeasonLeaderBoard
        driverStandings={driverStandings}
        number={10}
        teamStandings={teamStandings}
      />
    </Layout>
  );
};

export default PostRace;
