import React from 'react';

import styles from './VideoButton.module.scss';

type Props = {
  videoId: String;
};

const VideoButton: React.FunctionComponent<Props> = ({ videoId }) => {
  return (
    <>
      <a className={styles.button__play} href={`https://www.youtube.com/watch?v=${videoId}`} target="_blank">
        Highlights
      </a>
    </>
  );
};

export default VideoButton;
