import React from 'react';
import classNames from 'classnames';

import styles from './Section.module.scss';

const Section = ({ children, theme = 'dark' }) => {
  return <div className={classNames(styles.wrapper, styles[theme])}>{children}</div>;
};

export default Section;
