import React from 'react';
import classNames from 'classnames';

import styles from './Stats.module.scss';
import QualifyingStats from './QualifyingStats/QualifyingStats';

const findPoleSitter = (stats) => stats.find((stat) => stat.poles !== null);

const Stats = ({ stats, type }) => {
  if (stats && type === 'qualifying') {
    return <QualifyingStats
      driver={findPoleSitter(stats.drivers)}
      team={findPoleSitter(stats.teams)}
    />;
  }
  return null;
};

export default Stats;

/*
// qualifying stats
poles
seasonPoles
consecutivePoles

// race stats
races
lastRace
wins
seasonWins
consecutiveWins
podiums
seasonPodiums
consecutivePodiums
fastest
consecutiveFastest
retires
seasonRetires
consecutiveRetires
*/
