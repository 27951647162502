import React from 'react';
import classNames from 'classnames';

import styles from './Header.module.scss';
import Layout from '../../furniture/Layout/Layout';
import getTeamNameClassName from '../../../utils/getTeamNameClassName';

type Props = {
  country: string;
  description: string;
  shortTitle: string;
  title: string;
  winningTeam: string;
};

const Header: React.FunctionComponent<Props> = ({
  country,
  description,
  shortTitle,
  title,
  winningTeam,
}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        getTeamNameClassName(winningTeam || 'unknown')
      )}
    >
      <div className={styles.background}>
        <img className={styles.image} src="/images/races/2020/3.jpg" />

        <div className={styles.container}>
          <svg className={styles.textHighlight} viewBox="0 0 56 18">
            <text
              x="0"
              y="18"
              lengthAdjust="spacingAndGlyphs"
              textLength="100%"
            >
              {shortTitle}
            </text>
          </svg>
          <div className={styles.textBlock}>
            <Layout>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.description}>{description}</p>
              <div
                className={styles.flag}
                style={{
                  backgroundImage: `url(/images/flags/${country.toLowerCase()}.svg)`,
                }}
              />
            </Layout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
