import React from 'react';

import styles from './SectionTitle.module.scss';

const SectionTitle = ({
  title
}) => {
  return <h1 className={styles.title}>{title}</h1>
};

export default SectionTitle;
