import React from 'react';
import classNames from 'classnames';

import styles from './Results.module.scss';
import getTeamNameClassName from '../../utils/getTeamNameClassName';

const Results = ({ results }) => {
  return (
    <table className={styles.results}>
      <tbody>
        {results.map((result) => {
          const change = !isNaN(parseInt(result.position, 10))
            ? result.grid - result.position
            : '-';

          const changeStyle = change < 0 ? 'down' : change > 0 ? 'up' : 'same';

          return (
            <tr className={styles.row} key={result.driver.name}>
              <td
                className={classNames(
                  styles.position,
                  styles[changeStyle]
                )}
              >
                {result.position}
              </td>
              <td className={classNames(
                styles.change,
                styles.teamColor,
                getTeamNameClassName(result.team?.slug)
              )}>
                {change > 0 ? `+${change}` : change}{' '}
              </td>
              <td className={styles.entry}>
                <strong>{result.driver.name}</strong> {result.team.name}
              </td>
              <td className={styles.laps}>{result.laps} laps</td>
              <td className={styles.time}>{result.time}</td>
              <td className={styles.points}>
                {result.points && `${result.points}pts`}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Results;
