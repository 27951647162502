import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './ListByYear.module.scss';
import getTeamNameClassName from '../../utils/getTeamNameClassName';

type Props = {
  list: Array<Item>;
};

type Item = {
  season: string;
  round: string;
  driver: Driver;
  team: Team;
};

type Driver = {
  name: string;
  slug: string;
};

type Team = {
  name: string;
  slug: string;
};

const ListByYear: React.FunctionComponent<Props> = ({ list }) => {
  const limitedView = 5;
  const [seeAll, setSeeAll] = useState(false);

  let buttonAction = seeAll ? 'Reduce' : 'Expand';

  return (
    <div className={styles.wrapper}>
      <ul
        className={classNames(
          styles.list,
          seeAll ? styles.list_full : undefined
        )}
      >
        {(list || []).slice(0, seeAll ? 100 : limitedView).map((item: Item) => (
          <li className={styles.item} key={`${item.season}-${item.round}`}>
            <span className={styles.item_season}>{item.season}</span>
            <span className={styles.item_driver}>{item.driver.name}</span>
            <span
              className={classNames(
                styles.item_team,
                getTeamNameClassName(item.team.slug)
              )}
            >
              {item.team.name}
            </span>
          </li>
        ))}
      </ul>
      <button
        aria-label={buttonAction}
        className={styles.button}
        onClick={() => setSeeAll(!seeAll)}
      >
        <span
          className={classNames(
            styles.button_icon,
            styles[`button_${buttonAction.toLowerCase()}`]
          )}
        />
      </button>
    </div>
  );
};

export default ListByYear;
