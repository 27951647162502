import React from 'react';

import Layout from '../../furniture/Layout/Layout';
import SectionTitle from '../../furniture/SectionTitle/SectionTitle';

import styles from './CircuitInfo.module.scss';
import ListByYear from '../../ListByYear/ListByYear';

const CircuitInfo = ({ winners, fastest, numberOfLaps, race }) => {
  const previousWinners = winners.filter(
    (winner) => winner.season !== race.season || winner.round < race.round
  );

  return (
    <Layout>
      <SectionTitle
        title={`${race.circuit.name}, ${race.circuit.location.country}`}
      />

      <div className={styles.container}>
        <dl className={styles.stats}>
          <dd className={styles.key}>Location</dd>
          <dt className={styles.value}>
            {race.circuit.location.city}, {race.circuit.location.country}
          </dt>
          {previousWinners.length ? (
            <>
              <dd className={styles.key}>Laps</dd>
              <dt className={styles.value}>{numberOfLaps}</dt>
            </>
          ) : null}
          {fastest ? (
            <>
              <dd className={styles.key}>Fastest time</dd>
              <dt className={styles.value}>
                <p>
                  {fastest?.time}
                  <br />
                  {fastest.driver.name}, {fastest.team.name} ({fastest.season})
                </p>
              </dt>
            </>
          ) : null}
          {previousWinners.length ? (
            <>
              <dd className={styles.key}>Previous winners</dd>
              <dt className={styles.value}>
                <ListByYear list={previousWinners} />
              </dt>
            </>
          ) : null}
        </dl>
        <div className={styles.circuits}>
          <div className={styles.circuit} />
          <a
            href={`https://www.google.com/maps/@${race.circuit.location.lat},${race.circuit.location.lng},2000m/data=!3m1!1e3`}
            target="_blank"
          >
            View in Google Maps
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default CircuitInfo;
