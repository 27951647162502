import React from 'react';

import QualifyingTable from '../../Qualifying/Qualifying';
import Stats from '../../Stats/Stats';
import VideoButton from '../../../VideoButton/VideoButton';

const Qualifying = ({ results, stats, video }) => {
  return (
    <>
      <QualifyingTable results={results} />
      <Stats stats={stats} type="qualifying" />

      {video && <VideoButton videoId={video.url} />}
    </>
  );
};

export default Qualifying;
