import React from 'react';
import StartingGrid from '../../StartingGrid/StartingGrid';
import lookupBySlug from '../../../../utils/lookupBySlug';
import VideoButton from '../../../VideoButton/VideoButton';
import SectionTitle from "../../../furniture/SectionTitle/SectionTitle"
import Layout from "../../../furniture/Layout/Layout";
import Results from '../../../Results/Results';

const Race = ({ allLapsLead, drivers, race, results, video }) => {
  const sortedResults = [...results].sort((a,b) => {
    const aPos = (isNaN(parseInt(a.position, 10))) ? 100 : parseInt(a.position, 10);
    const bPos = (isNaN(parseInt(b.position, 10))) ? 100 : parseInt(b.position, 10);

    if (aPos === 100 && bPos === 100) {
     return b.laps - a.laps;
    }

    return aPos - bPos;
  });

  return (
    <Layout>
      <StartingGrid results={results} season={race.season} />
      As it happened
      <h2>Laps Lead</h2>
      <ul>
        {allLapsLead.map((lap) => (
          <li key={lap.driver}>
            {`${lookupBySlug(lap.driver, drivers).name} ${lap.lapsLead}`} laps
          </li>
        ))}
      </ul>
      {video && <VideoButton videoId={video.url} />}

      <SectionTitle title={'Results'} />
      <Results results={sortedResults} />
    </Layout>
  );
};

export default Race;
