import React from 'react';
import classNames from 'classnames';

import DriverPhoto from '../../../DriverPhoto/DriverPhoto';

import styles from './GridPosition.module.scss';
import getTeamNameClassName from '../../../../utils/getTeamNameClassName';

const GridPosition = ({
  driverName,
  driverSlug,
  position,
  teamName,
  teamSlug,
  time,
}) => {
  return (
    <div className={styles.slot}>
      <span
        className={styles.position}
      >
        {position}
      </span>
      <DriverPhoto slug={driverSlug} />
      <h1 className={styles.driverName}>{driverName}</h1>
      <h2 className={styles.teamName}>
        <span
          className={classNames(
            styles.teamColor,
            getTeamNameClassName(teamSlug)
          )}
        />
        {teamName}
      </h2>
      <h3 className={styles.time}>
        {time.time} {time.round ? `(${time.round})` : ''}{' '}
        {time.position < position
          ? `PEN ${position - time.position} places`
          : ''}
      </h3>
    </div>
  );
};

export default GridPosition;
