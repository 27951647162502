const getOrdinalSuffix = (value) => {
  const mod10 = value % 10;
  const mod100 = value % 100;
  if (mod10 == 1 && mod100 != 11) {
    return 'st';
  }
  if (mod10 == 2 && mod100 != 12) {
    return 'nd';
  }
  if (mod10 == 3 && mod100 != 13) {
    return 'rd';
  }
  return 'th';
};

export default getOrdinalSuffix;
