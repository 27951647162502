import React from 'react';

const StandingsStats = ({ driverStandings, preRace, round, teamStandings }) => {

  if (!driverStandings.length || !teamStandings.length) {
    return null;
  }

  const leadingDriver = driverStandings[0].driver.name;
  const leadingTeam = teamStandings[0].team.name;

  const driverPointsDifference =
    parseInt(driverStandings[0].points, 10) -
    parseInt(driverStandings[1].points, 10);
  const teamPointsDifference =
    parseInt(teamStandings[0].points, 10) -
    parseInt(teamStandings[1].points, 10);

  const driverPositionChange = driverStandings[0].previous !== driverStandings[0].position;
  const teamPositionChange = teamStandings[0].previous !== teamStandings[0].position;

  return (
    <>
      <p>
        {preRace && round === 1
          ? `${leadingDriver} won last year's drivers championship.`
          : `${leadingDriver} ${driverPositionChange ? 'now leads' : 'continues to lead'} the drivers championship by ${driverPointsDifference}pts.`}
      </p>

      <p>
        {preRace && round === 1
          ? `${leadingTeam} won last year's constructors championship.`
          : `${leadingTeam} ${teamPositionChange ? 'now leads' : 'continues to lead'} the constructors championship by ${teamPointsDifference}pts.`}
      </p>
    </>
  );
};

export default StandingsStats;
