import React from 'react';

import Layout from '../../../furniture/Layout/Layout';
import Stat from '../Stat/Stat';

import getOrdinalSuffix from '../../../../utils/getOrdinalSuffix';

import styles from './QualifyingStats.module.scss';
import SectionTitle from "../../../furniture/SectionTitle/SectionTitle"

const QualifyingStats = ({ driver, team }) => {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <Stat
          icon={'trophy'}
          stat={driver.poles}
          statSuffix={getOrdinalSuffix(driver.poles)}
          text={'pole'}
          winner={driver.driver.name}
        />
        {driver.consecutivePoles && (
          <Stat
            icon={'trophy'}
            stat={driver.consecutivePoles}
            statSuffix={getOrdinalSuffix(driver.consecutivePoles)}
            text={'consecutive pole'}
            winner={driver.driver.name}
          />
        )}
        <Stat
          icon={'trophy'}
          stat={team.poles}
          statSuffix={getOrdinalSuffix(team.poles)}
          text={'pole'}
          winner={team.team.name}
        />
        {team.consecutivePoles && (
          <Stat
            icon={'trophy'}
            stat={team.consecutivePoles}
            statSuffix={getOrdinalSuffix(team.consecutivePoles)}
            text={'consecutive pole'}
            winner={team.team.name}
          />
        )}
      </div>
    </Layout>
  );
};

export default QualifyingStats;

/*
// qualifying stats
poles
seasonPoles
consecutivePoles

// race stats
races
lastRace
wins
seasonWins
consecutiveWins
podiums
seasonPodiums
consecutivePodiums
fastest
consecutiveFastest
retires
seasonRetires
consecutiveRetires
*/
