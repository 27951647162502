import React from 'react';

import styles from './DriverPhoto.module.scss';

const DriverPhoto = ({ slug }) => {
  return (
    <div
      className={styles.driverPhoto}
      style={{ backgroundImage: `url(/images/drivers/2020/${slug}.png)` }}
    />
  );
};

export default DriverPhoto;
